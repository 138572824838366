
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  toRefs
} from 'vue'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxHeaderFilter,
  DxFilterRow,
  DxExport,
  DxColumnChooser,
  DxGroupPanel,
  DxPaging
} from 'devextreme-vue/data-grid'
import {
  DxLoadPanel
} from 'devextreme-vue/load-panel'
import {
  Icon
} from '@iconify/vue'
/* eslint-disable vue/no-setup-props-destructure */
/* eslint-disable vue/no-dupe-keys */
// eslint-disable-next-line import/no-extraneous-dependencies
import exclamationIcon from '@iconify-icons/bi/exclamation'

export default {
  name: 'TableComissionPayment',
  components: {
    DxColumn,
    DxGrouping,
    DxDataGrid,
    DxLoadPanel,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxColumnChooser,
    DxGroupPanel,
    Icon
    // DxPaging
  },
  props: {
    data: {
      default: () => []
    }
  },
  emits: ['payComission'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers,
      $strInd
    } = app!.appContext.config.globalProperties
    const icons = ref({
      exclamationIcon
    })
    const loading = ref(false)
    const noDataText = ref('Tidak Ada Data')
    const focusedRowKey = ref(17)
    const showHeaderFilter = ref(true)
    const showFilterRow = ref(true)
    const autoExpandAll = ref(true)
    const selectedReceipt = ref(null)
    const position = ref({
      of: '#delivery-item-table-id'
    })
    const displayMode = ref('full')
    const {
      data
    } = toRefs(props)
    const payComission = (val: any) => {
      emit('payComission', val)
    }
    // console.log('any data receive', data)

    // onMounted(() => {
    //   getAllData()
    // })

    return {
      // eslint-disable-next-line vue/no-dupe-keys
      data,
      loading,
      selectedReceipt,
      moment,
      autoExpandAll,
      focusedRowKey,
      noDataText,
      showHeaderFilter,
      showFilterRow,
      position,
      displayMode,
      icons,
      payComission
    }
  }
}
