import {
  ref,
  getCurrentInstance,
  reactive,
  computed,
  onMounted
} from 'vue'
import {
  environment
} from '@/utils'
import {
  newPackageUseCase,
  receiveItemFileUseCase,
  comissionPaymentUseCase
} from '@/domain/usecase'
import { useDropzone } from 'vue3-dropzone'
import { takePhoto } from '@/plugins/system/camera'
import { compressImage } from '@/plugins/compressor'

export default {
  name: 'FormManualComission',
  props: {
    isShowDialog: {
      default: () => false
    },
    dataForm: {
      default: () => Object
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const hideDialog = () => {
      emit('hideDialog')
    }
    const API_BASE = environment.getApiBase()
    const propertiesProps = reactive(props)
    const reason = ref(null)
    // const isShowDialog = reactive(props.isShowDialog)
    // const dataPackage = reactive(props.dataPackage)
    const dataPayment = ref(propertiesProps.dataForm)
    const isShowModal = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          hideDialog()
        }
      }
    })

    const dataTransferCourier = ref([])
    const isTransferCourier = () => {
      dataTransferCourier.value = dataPayment.value.filter((x: any) => x.IsActive)
      if (dataTransferCourier.value.length > 0) {
        return true
      }
      return false
    }

    const dataChangeCourier = ref([])
    const isChangeCourier = () => {
      dataChangeCourier.value = dataPayment.value.filter((x: any) => !x.IsActive)
      if (dataChangeCourier.value.length > 0) {
        return true
      }
      return false
    }

    const processMappingPackage = (list: any) => {
      const mapArr = list.map((x: any) => ({
        IdPaket: x.IdPaket
      }))
      return mapArr
    }

    const responseResult = (res: any) => {
      if (!res.error) {
        $toast.add({
          severity: 'success',
          detail: res.result.detail ?? res.result.Detail,
          group: 'bc',
          life: 2000
        })
        hideDialog()
        emit('reloadData')
      } else {
        $toast.add({
          severity: 'error',
          detail: res.message,
          group: 'bc',
          life: 2000
        })
      }
    }

    /**
     * Upload Start
     */
    const isDragActive = ref(false)
    const listImage = ref([]) as any
    const listPathUpload = ref([]) as any

    const processUploadPhoto = async (image: any) => {
      for (let i = 0; i < image.length; i++) {
        const tempImage = image[i]
        const formData = new FormData()
        formData.append('file', tempImage.image)
        // eslint-disable-next-line no-await-in-loop
        await receiveItemFileUseCase.submitFile('payout', formData).then((res) => {
          if (!res.error) {
            listPathUpload.value.push(res.result)
          }
        })
      }
    }

    const checkSrcListImage = () => listImage.value.filter((image: any) => image.src === null).length

    const generateListImage = () => {
      for (let index = 0; index < 1; index++) {
        listImage.value.push({
          name: 'add-image',
          src: null,
          image: null
        })
      }
    }

    const pushListImage = async (item: any, from: any = '') => {
      let file = item
      if (from === 'camera') {
        // file = new File([item], 'Name', {
        //   lastModified: moment().unix(),
        //   type: item.type,
        // })
        item = item.image
      } else {
        // eslint-disable-next-line no-await-in-loop
        file = await compressImage(file)
        file = new File([file], file.name)
      }
      for (let index = 0; index < listImage.value.length; index++) {
        const image = listImage.value[index]
        if (image.src === null) {
          listImage.value[index].src = URL.createObjectURL(item)
          listImage.value[index].image = file
          break
        }
      }
    }

    const removeImage = (index: number) => {
      listImage.value[index].src = null
      listImage.value[index].image = null
    }

    const resetImage = () => {
      for (let index = 0; index < listImage.value.length; index++) {
        listImage.value[index].src = null
        listImage.value[index].image = null
      }
    }

    function onDrop(acceptFiles: any, rejectReasons: any) {
      if (checkSrcListImage() >= acceptFiles.length) {
        for (let index = 0; index < acceptFiles.length; index++) {
          if (acceptFiles[index].type.includes('image')) {
            pushListImage(acceptFiles[index])
          } else {
            $toast.add({
              severity: 'error', detail: 'Hanya support tipe file gambar (png/jpg/jpeg)', group: 'bc', life: 1500
            })
          }
        }
      } else {
        $toast.add({
          severity: 'error', detail: 'Maksimal upload gambar sebanyak 5', group: 'bc', life: 1500
        })
      }
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })

    const showCamera = async () => {
      const response = await takePhoto() as any
      pushListImage(response, 'camera')
    }
    /**
     * Upload End
     */

    const processTransferOrChange = async () => {
      console.log('data', dataPayment.value)
      const dataSend: any = {
        Id: dataPayment.value.Id,
        Filename: listPathUpload.value[0].Filename,
        Notes: reason.value,
      }
      await comissionPaymentUseCase.submitManualPayout(dataSend).then((response: any) => {
        console.log('response agter send', response)
        if (response.error) {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
          emit('reloadData')
        }
      })
    }

    const processSubmit = async () => {
      await processUploadPhoto(listImage.value)
      processTransferOrChange()
    }

    const submit = () => {
      console.log('any lis', listImage.value)
      console.log('cehe', checkSrcListImage())
      if (reason.value && checkSrcListImage() === 0) {
        processSubmit()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan alasan sudah diisi',
          group: 'bc',
          life: 1500
        })
      }
    }

    onMounted(() => {
      generateListImage()
    })

    return {
      isShowModal,
      hideDialog,
      dataPayment,
      API_BASE,
      reason,
      submit,
      removeImage,
      resetImage,
      showCamera,
      generateListImage,
      getRootProps,
      getInputProps,
      onDrop,
      listImage,
      isDragActive
    }
  }
}
